// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery';
global.$ = jQuery;

import 'select2';
require ("select2/dist/css/select2.min.css")

document.addEventListener("turbolinks:load", () => {$('.select2').select2({tags: true})})
// document.addEventListener("turbolinks:load", () => {$(".alert").delay(2000).slideUp(500, function(){$(".alert").alert('close')})})

require("@popperjs/core")
import "bootstrap"
window.bootstrap = bootstrap

import 'bootstrap-icons/font/bootstrap-icons.css'
// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover, Modal } from "bootstrap"

// The stylesheet location we created earlier
// require("../stylesheets/application.scss")

document.addEventListener("turbolinks:load", () => {
	// Both of these are from the Bootstrap 5 docs
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
	var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
		return new Tooltip(tooltipTriggerEl)
	})

	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
	var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
		return new Popover(popoverTriggerEl)
	})
})

let bsOffCanvas;

// import amcharts4 from '@amcharts/amcharts4'
import {tabulator} from "./tabulator"
import {tinymce} from "./tinymce"
import {ace} from "./ace"

import {theme} from "./theme"
document.addEventListener("turbolinks:load", () => {
	theme.init()

	// Offcanvas

	var navbar = document.querySelector(".navbar");

	const navOffCanvasBtn = document.querySelectorAll(".offcanvas-nav-btn");
	const navOffCanvas = document.querySelector(".navbar:not(.navbar-clone) .offcanvas-nav");
// Declare bsOffCanvas outside the if block

// Check if the navOffCanvas element exists before creating the Offcanvas instance
	if (navOffCanvas) {
		bsOffCanvas = new bootstrap.Offcanvas(navOffCanvas, { scroll: true });

		// Event listeners
		navOffCanvasBtn.forEach((e) => {
			console.log(e)

			e.addEventListener("click", (event) => {
				toggleOffCanvas();
			});
		});
	}

// Function to toggle the Offcanvas
	function toggleOffCanvas() {
		if (bsOffCanvas && bsOffCanvas._isShown) {
			bsOffCanvas.hide();
		} else if (bsOffCanvas) {
			bsOffCanvas.show();
		}
	}

})

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

global.am4core = am4core
global.am4charts = am4charts
global.am4themes_animated = am4themes_animated

import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

document.addEventListener("turbolinks:load", () => {
	flatpickr(".flatpickr-multiple", {
		altFormat: "d/m/Y",
		altInput: true,
		dateFormat: "d/m/Y",
		mode: 'multiple',
	})
})

document.addEventListener("turbolinks:load", () => {
	flatpickr(".flatpickr-single", {
		altFormat: "d/m/Y",
		altInput: true,
		dateFormat: "d/m/Y",
		mode: 'single',
	})
})

document.addEventListener("turbolinks:load", () => {
	flatpickr(".flatpickr-range", {
		altFormat: "d/m/Y",
		altInput: true,
		dateFormat: "d/m/Y",
		mode: 'range',
	})
})

import "@fortawesome/fontawesome-free/js/all";
require("@nathanvda/cocoon")

/// With a tabbed form any validation errors may be hidden in hidden tabs so
// display a prominent message and hightlight the failing tabs with red bold text.
var show_errors_in_tabs = function ()
{
	var $tab_id_with_error = $('.form-group-invalid').parents('.tab-pane')

	if ($tab_id_with_error.length > 0)
	{
		$tab_id_with_error.each(function (e) {
			var tab_id = this.getAttribute('id')
			var tab = document.getElementsByClassName(tab_id)[0]

			if (tab)
			{
				tab.style.color = 'red'

				// Check if a sub tab was found and if so update its parent tab to red.
				var match = /(tab-class-\d+)-(\d+)/.exec(tab_id)
				if (match[2])
				{
					document.getElementsByClassName(match[1])[0].style.color = 'red'
				}
			}
		}
		)
	}
}
document.addEventListener("turbolinks:load", () => {show_errors_in_tabs ()})

// Remember the tab that was selected so after the page is refreshed directly or because an
// update was done we stay on the selected tab rather than always going to the first tab.
var remember_tab_setting = function () {

	$(':button[data-bs-toggle="tab"]').click('show.bs.tab', function(e) {
		localStorage.setItem('activeTab', $(e.target).text());
	});
	var activeTab = localStorage.getItem('activeTab');
	if(activeTab)
	{
		let tab = $(':button[data-bs-toggle="tab"]').filter(function() {return $(this).text() ===  activeTab})
		bootstrap.Tab.getOrCreateInstance(tab).show()
	}
}
document.addEventListener("turbolinks:load", () => {remember_tab_setting ()})

import './add_eu_banner'

