// global.Tabulator = require('tabulator-tables');
// import tabulator from 'tabulator-tables'

import {TabulatorFull as Tabulator} from 'tabulator-tables'
global.Tabulator = Tabulator

require ("tabulator-tables/dist/css/tabulator_materialize.min.css")

import {DateTime} from 'luxon'
window.DateTime = DateTime

function edit_helper (key, cell, callback)
{
	$.ajax({
		url: cell.getTable().getAjaxUrl().replace('.json', `/${cell.getRow().getIndex()}.json`),
		data: {[key]: {[cell.getColumn().getField()]: cell.getValue()}},
		type: "patch",
		success: function(response, textStatus, xhr)
		{
			cell.getRow().update(response)
			if (callback) callback ()
		},
		error: function(XMLHttpRequest, textStatus, error)
		{
			cell.restoreOldValue();  
			alert(XMLHttpRequest.responseText);
		}
   })
}
global.edit_helper = edit_helper

function show_selected_row (row, div_id)
{
	$.ajax({
		url: row.getTable().getAjaxUrl().replace('.json', `/show/${row.getIndex()}`),
		type: "get",
		cache: false,
		success: function(html)
		{
 		 $(div_id).html(html)
		},
		error: function(XMLHttpRequest, textStatus, error)
		{
			alert(XMLHttpRequest.responseText);
		}
   })
}
global.show_selected_row = show_selected_row

function goto_edit_form (row)
{
	window.location = row.getTable().getAjaxUrl().replace('.json', `/${row.getIndex()}/edit`)
}
global.goto_edit_form = goto_edit_form

function goto_show_form (row)
{
	window.location = row.getTable().getAjaxUrl().replace('.json', `/${row.getIndex()}`)
}
global.goto_show_form = goto_show_form

function goto_duplicate_form (row)
{
	window.location = row.getTable().getAjaxUrl().replace('.json', `/new?dup_id=${row.getIndex()}`)
}
global.goto_show_form = goto_show_form

global.rowMenu = [
    {label: "Edit", 			action: function(e, row){goto_edit_form(row)}},
    {label: "Show", 			action: function(e, row){goto_show_form(row)}},
    {label: "Duplicate", 	action: function(e, row){goto_duplicate_form(row)}},
    {label: "History", 		action: function(e, row){goto_edit_form(row)}},
	]

function row_diff_in_seconds (aRow, bRow, seconds_field)
{
	return aRow.getData()[seconds_field] - bRow.getData()[seconds_field]
}
global.row_diff_in_seconds = row_diff_in_seconds
