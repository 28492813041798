global.ace = require('ace-builds');

// import ace from 'ace-builds'
import * as ace from 'ace-builds/src-noconflict/ace';

// import webpack resolver to dynamically load modes, you need to install file-loader for this to work!
import "ace-builds/webpack-resolver";

// ace.config.set('basePath', '/')

document.addEventListener("turbolinks:load", () => {
  // Hook up ACE editor to all textareas with data-editor attribute
	$('textarea[data-editor]').each(function () 
	{
    var textarea = $(this);

    var mode = textarea.data('editor');

    var editDiv = $('<div>', {
        position: 'absolute',
        width: textarea.width(),
        height: textarea.height(),
        'class': textarea.attr('class')
    }).insertBefore(textarea);

    textarea.css('display', 'none')

    var editor = ace.edit(editDiv[0]);
    editor.renderer.setShowGutter(true);
		
    editor.getSession().setValue(textarea.val());
    editor.getSession().setMode("ace/mode/" + mode);
    // editor.setTheme("ace/theme/idle_fingers");
  
    // copy back to textarea on form submit...
    textarea.closest('form').submit(function () {
        textarea.val(editor.getSession().getValue());
    })
  })
})
