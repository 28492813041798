import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver/theme'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/hr'

document.addEventListener("turbolinks:load", () => {
    tinymce.init({
      selector: "textarea:not(.no_tinymce)",
      toolbar: [
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code | help"
      ],
	  menu: {
	    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
	    view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
	    insert: { title: 'Insert', items: 'image link media template inserttable | charmap hr | insertdatetime' },
	    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
	    table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
	    help: { title: 'Help', items: 'help' }
	  },
      plugins: "image,link,code,autoresize,imagetools,media,table,insertdatetime,charmap,preview,anchor,searchreplace,visualblocks,fullscreen,imagetools,help,hr,lists, advlist",
	  skin: false,
      content_css: ['/assets/tinymce_content.min.css']
	  
    });
});