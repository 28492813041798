// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)

// Expose action cable.  Prevent all channels being added to all views.
// (https://dev.to/lucaskuhn/a-simple-guide-to-action-cable-2dk2).
import * as ActionCable from '@rails/actioncable'

window.App || (window.App = {});
window.App.cable = ActionCable.createConsumer();
